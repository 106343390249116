import { isMobileBreakpoint, getPreviewModeData } from './service';
import * as elchspuckeService from '../../elchspucke-tuicom.service';
import { isLegacyFont } from '../../legacyFont';

/* Get coupons on the page */
export function getCoupons() {
    return document.querySelectorAll('tui-coupon') ?? [];
}

/* Apply page data to all coupons in the array */
export function applyPageData(pageData) {
    /* In "overwrites" we can change the backend controllers behavior like date, etc. */
    const overwrites = getPreviewModeData();

    if (pageData && overwrites?.pageData?.audienceBadges?.length) { 
        pageData.audienceBadges = overwrites.pageData.audienceBadges;
    }

    return getCoupons().forEach(($coupon) => {
        $coupon.applyPageData({
            pageData,
            ...(overwrites ? { overwrites } : {})
        });
    });
}

/**
 * Inject coupon mobile or flyin depending on screen width
 * @param {*} $couponElement
 */
export function injectResponsiveCoupon({ language, tenant, pageData }) {

    // Remove existing coupons
    const existingCoupons = document.querySelectorAll('tui-coupon');
    existingCoupons.forEach((coupon) => coupon.remove());

    // Create a new Coupon
    const $couponElement = document.createElement('tui-coupon');
    $couponElement.setAttribute('data-theme', 'tui-light');
    if(isLegacyFont()) {
        $couponElement.setAttribute('data-legacy-font', '');
    }
    $couponElement.setAttribute('locale', language);
    $couponElement.setAttribute('tenant', tenant);

    // There shouldn't be a link on the coupon when on BU / checkout page.
    $couponElement.setAttribute('use-tooltip-hyperlink', !(pageData?.pageType?.ibeBu));

    if (isMobileTeaserCouponVisible(pageData)) {
        injectMobileCoupon($couponElement, pageData);
    } else {
        injectFlyInCoupon($couponElement, pageData);
    }

    return $couponElement;
}

function isMobileTeaserCouponVisible(pageData) {
    return (
        isMobileBreakpoint() &&
        (pageData?.ibeSearchStep?.regionList ||
            pageData?.ibeSearchStep?.hotelList ||
            pageData?.ibeSearchStep?.offerList ||
            pageData?.pageType?.editorial)
    );
}

export function renderMobileTeaserInHotelList($couponElement) {
    var teaserRepeater = 6;

    const existingCoupons = document.querySelectorAll('#t-hotel-list .teaser-coupon-code');
    existingCoupons.forEach((coupon) => coupon.remove());

    // Auswertung A/B-Test https://jira.tuigroup.com/browse/STORY-19107
    let i = 0;
    if (elchspuckeService.getCookie('_vis_opt_exp_18742_combi') === '2') {
        i = 2;
    }

    const couponInjectSelector = document.querySelectorAll('#t-hotel-list article');
    for (i; i < couponInjectSelector.length; i = i + teaserRepeater) {
        const couponDiv = document.createElement('div');
        couponDiv.setAttribute('class', 'teaser-coupon-code');
        couponDiv.appendChild($couponElement.cloneNode(true));
        couponInjectSelector[i].parentNode.insertBefore(couponDiv, couponInjectSelector[i]);
    }
}

/**
 * Remove existing coupons and inject a new one.
 * Since we need two different cottonBall events to initialize the coupon
 * we need to prevent multiple coupons to appear in the landingpage.
 * The later events keep the more comprehensive pageData (e.g. airport) 
 * that is why we remove the existing coupons in favour of the later ones.
 * @param {*} couponData 
 */
export function renderMobileTeaserInLandingPage($couponElement, pageData) {
    const existingCoupons = document.querySelectorAll('.teaser-coupon-code');
    existingCoupons.forEach((coupon) => coupon.remove());
    injectMobileCoupon($couponElement, pageData);
}

/**
 * Inject the desktop coupon and remove the mobile coupons
 * @param {*} $couponElement
 */
function injectFlyInCoupon($couponElement, pageData) {
    $couponElement.setAttribute('class', 'coupon-flyin');
    $couponElement.setAttribute('variant', 'M');
    $couponElement.setAttribute('flyin', 'true');
    $couponElement.setAttribute('flyin-z-index', '1030');
    $couponElement.setAttribute('flyin-position-top', '135px');
    $couponElement.setAttribute('use-tooltip-hyperlink', !pageData?.pageType?.ibeBu ?? true);
 
    document.body.appendChild($couponElement);

    // Push the letter further down 
    // to prevent a collision with the coupon
    const flap = document.querySelector('.flap');
    if (flap) {
        flap.style.top = '50%';
        flap.style.transform = 'translateY(-50%)';
    }
}

/**
 * Inject the mobile container
 * @param {*} $couponElement
 */
function injectMobileCoupon($couponElement, pageData) {
    $couponElement.setAttribute('class', 'coupon-mobile');
    $couponElement.setAttribute('variant', 'S');
    $couponElement.setAttribute('flyin', 'false');

    injectMobileCouponLegacy($couponElement, pageData);
}

/**
 * This legacy code is from the old mojo.
 * @param {*} $couponElement
 */
async function injectMobileCouponLegacy($couponElement, pageData) {
    let couponInjectSelector, couponDiv;

    if (pageData.pageType.editorial) {
        couponInjectSelector = !document.querySelectorAll('div.ibe-Homesearch')[0].nextElementSibling
            ? document.querySelectorAll('div.ibe-Homesearch')[0].parentNode.nextElementSibling.firstChild
            : document.querySelectorAll('div.ibe-Homesearch')[0].nextElementSibling;
        couponDiv = document.createElement('div');
        couponDiv.setAttribute('class', 'teaser-coupon-code');
        couponDiv.setAttribute('style', 'margin: 20px 0 20px 0');
        couponDiv.appendChild($couponElement.cloneNode(true));
        couponInjectSelector?.parentNode.insertBefore(couponDiv, couponInjectSelector);
    } else if (pageData.ibeSearchStep.regionList) {
        couponInjectSelector = document.querySelectorAll('#tuiResult section')[0];
        couponDiv = document.createElement('div');
        couponDiv.setAttribute('class', 'teaser-coupon-code');
        couponDiv.setAttribute('style', 'margin-top: 8px');
        couponDiv.appendChild($couponElement.cloneNode(true));
        couponInjectSelector?.parentNode.insertBefore(couponDiv, couponInjectSelector);
    } else if (pageData.ibeSearchStep.hotelList) {
        renderMobileTeaserInHotelList($couponElement);
    } else if (pageData.ibeSearchStep.offerList) {
        // https://jira.tuigroup.com/browse/STORY-20412
        let couponSelector = document.querySelector('[name=offerBundle]') || document.querySelector('#tuiHotelNavigation');
        if (couponSelector) {
            couponDiv = document.createElement('div');
            couponDiv.setAttribute('class', 'teaser-coupon-code');
            couponDiv.setAttribute('style', 'margin: 0;');
            couponDiv.appendChild($couponElement.cloneNode(true));
            if (couponSelector.id !== 'tuiHotelNavigation') {
                elchspuckeService.insertBefore(couponDiv, couponSelector);
            } else {
                elchspuckeService.insertAfter(couponDiv, couponSelector);
            }
        }
    }
}
